import icon from "./icon";

// ------------------------------------------------
// ピクトグラム系
// ------------------------------------------------

// メール
export const EmailIcon = icon("envelope", "EmailIcon");
// 受信箱
export const InboxIcon = icon("inbox", "InboxIcon");
// CSVファイル
export const CsvIcon = icon("file-code", "CsvIcon");
// PDFファイル
export const PdfIcon = icon("file-pdf", "PdfIcon");
// 圧縮ファイル
export const ArchiveIcon = icon("file-archive", "ArchiveIcon");
// フィルタ
export const FilterIcon = icon("filter", "FilterIcon");
// 省略（三点リーダ）
export const EllipsisIcon = icon("ellipsis-h", "EllipsisIcon");
// ヘルプ
export const HelpIcon = icon("question-circle", "HelpIcon");
export const HelpAltIcon = icon(["far", "question-circle"], "HelpAltIcon");
// 情報
export const InfoIcon = icon("info-circle", "InfoIcon");
// 警告
export const WarningIcon = icon("exclamation-triangle", "WarningIcon");
// 禁止
export const BanIcon = icon("ban", "BanIcon");
// エラー
export const ErrorIcon = icon("times-circle", "ErrorIcon");
// 成功
export const SuccessIcon = icon("check-circle", "SuccessIcon");
export const UnCheckBoxIcon = icon(["far", "square"], "UnCheckBoxIcon");
export const IncompleteCheckBoxIcon = icon(["far", "check-square"], "IncompleteCheckBoxIcon");
export const CheckBoxIcon = icon("check-square", "CheckBoxIcon");
export const ToggleOpenIcon = icon(["far", "plus-square"], "ToggleOpenIcon");
export const ToggleCloseIcon = icon(["far", "minus-square"], "ToggleCloseIcon");
// ジョブ
export const JobIcon = icon("truck-loading", "JobIcon");

// ログイン
export const LoginIcon = icon("sign-in-alt", "LoginIcon");
export const LogoutIcon = icon("sign-out-alt", "LogoutIcon");
// タグ
export const TagIcon = icon("tag", "TagIcon");
// メニュー（ハンバーガー）
export const MenuIcon = icon("bars", "MenuIcon");
// PC
export const PcIcon = icon("desktop", "PcIcon");
// スマートフォン
export const SmartPhoneIcon = icon("mobile-alt", "SmartPhoneIcon");
// 比較グラフ: ピッタリくるものがなく、他に "star-of-life" も検討した。
export const ComparisonChartIcon = icon("dharmachakra", "ComparisonChartIcon");
// ホーム
export const HomeIcon = icon("home", "HomeIcon");

// ------------------------------------------------
// 矢印系
// ------------------------------------------------

// 左矢印
export const LeftArrowIcon = icon("angle-left", "LeftArrowIcon");
// 右矢印
export const RightArrowIcon = icon("angle-right", "RightArrowIcon");
// 左端矢印
export const LeftestArrowIcon = icon("angle-double-left", "LeftestArrowIcon");
// 右端矢印
export const RightestArrowIcon = icon("angle-double-right", "RightestArrowIcon");
// 下矢印
export const DownArrowIcon = icon("angle-down", "DownArrowIcon");
// 下キャレット(三角形)
export const DownCaretIcon = icon("caret-down", "DownCaretIcon");
// 上キャレット(三角形)
export const UpCaretIcon = icon("caret-up", "UpCaretIcon");

// ------------------------------------------------
// ソート系
// ------------------------------------------------

// 並び替え
export const ReorderIcon = icon("sort-amount-down", "ReorderIcon");
// 同じ形のアイコンを別名で使うためのラッパ
export const SortDescIcon = icon("sort-down", "SortDescIcon");
export const SortAscIcon = icon("sort-up", "SortAscIcon");
export const SortNoneIcon = icon("sort", "SortNoneIcon");
// 並び替えのハンドラ
export const HandlerIcon = icon("bars", "HandlerIcon");
