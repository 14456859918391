/* eslint-disable */
import axios, { AxiosResponse } from "axios";
import qs from "qs";
import _ from "lodash";
import { ApiResponse, FetchParams, Apis } from "~/types/Api";
import { withTenantPath } from "~/utils/url";

axios.defaults.xsrfCookieName = "CSRF-TOKEN";
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
axios.defaults.withCredentials = true;

// Rails が期待するパラメータと整合するように調整
const adjustEmptyElement = (value: any) => {
  if (value == null) {
    // 値がundefined の場合、`qs.stringify({ a: undefined }) === ""` となってしまい
    // Rails での扱いが難しくなるため、null で置換する。（出力は "a=" となる）
    return null;
  }
  if (Array.isArray(value)) {
    // `qs.stringify({ a: [] }) === ""` となってしまい Rails での扱いが難しくなるため、[null] で置換する。（出力は "a=[]" となる）
    return _.isEmpty(value) ? [null] : value.map(adjustEmptyElement);
  }
  if (value instanceof FormData) {
    return value;
  }
  if (typeof value === "object") {
    // FormData のインスタンスも object となるため注意
    return _.mapValues(value, adjustEmptyElement);
  }

  return value;
};

// qsのデフォルトの挙動では、値が空配列の場合にキーごと除外される。
// しかしサーバ側では、意図したものかどうかを判別する必要があるため、
// [null] での置き換えと `strictNullHandling: true` オプションにより、空配列の場合でもデータが送信されるようにしている。
const paramsSerializer = (params: any) =>
  qs.stringify(adjustEmptyElement(params), { arrayFormat: "brackets", strictNullHandling: true });

type MethodType = "get" | "put" | "post" | "delete" | "patch";

type BuildRequestType = (
  method: MethodType,
  url: string,
  params?: Record<string, any> | null,
  format?: string,
) => Record<string, any>;

const buildRequest: BuildRequestType = (method, url, params, format = "json") => {
  const payload =
    method === "get" ? { params, paramsSerializer } : { data: adjustEmptyElement(params) };

  return {
    method,
    url: `${url}.${format}`,
    // AWS ELB のデフォルトタイムアウトが60秒なので、アプリではそれ未満の値とする。
    timeout: 55000,
    ...payload,
  };
};

interface SearchQuery {
  q: { sorts: string; [key: string]: any };
  page?: number | string;
}

export const buildSearchQuery = (fetchParams: Partial<FetchParams>): SearchQuery => {
  const { condition = {}, sort = null, page = null, ...others } = fetchParams;
  const sorts = sort ? `${_.snakeCase(sort.key.toString())} ${sort.dir}` : "";
  const pageParam = page ? { page: page || "" } : {};

  return { q: { ...condition, sorts }, ...pageParam, ...others };
};

const handleError = (err: any) => {
  if (!err.response) {
    const isTimeout = err.code === "ECONNABORTED";

    return {
      isSuccess: false,
      statusCode: 400,
      error: isTimeout ? "リクエストがタイムアウトしました。" : "リクエストが失敗しました。",
      data: null
    };
  }
  const {
    status: statusCode,
    data,
  } = err.response;

  const error = data.message;
  const errorCode = data.code;
  const errorDetails = data.errorDetails;

  return { isSuccess: false, statusCode, data, error, errorCode, errorDetails };
};

type RequestType = (
  method: MethodType,
  url: string,
  params?: Record<string, any>,
) => Promise<ApiResponse<AxiosResponse>>;

export const request: RequestType = async (method, url, params) => {
  const requestParams = buildRequest(method, withTenantPath(url), params);
  const apiResponse = await axios(requestParams)
    .then(response => {
      const { status: statusCode, data } = response;

      return { isSuccess: true, statusCode, data };
    })
    .catch(handleError);

  return apiResponse;
};

export const generateApi = (baseUri: string): Apis => ({
  indexApi: async (searchParams) =>
    request("get", `/${baseUri}`, buildSearchQuery(searchParams)),
  showApi: async params => {
    const { id, ...otherParams } = params;
    return request("get", `/${baseUri}/${id}`, otherParams);
  },
  saveApi: async params => {
    const { id } = params;
    return id
      ? request("put", `/${baseUri}/${id}`, params)
      : request("post", `/${baseUri}`, params);
  },
  deleteApi: async params => {
    const { id, ...otherParams } = params;
    return request("delete", `/${baseUri}/${id}`, otherParams);
  },
});
