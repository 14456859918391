import React from "react";
import { RecoilRoot } from "recoil";
import { RecoilExternalState } from "~/utils/recoil-external-state";
import { Layout, Row, ConfigProvider } from "antd";
import jaJP from "antd/lib/locale-provider/ja_JP";
import { useSetTenantName, useSetIsAdmin } from "~/contexts/GlobalContext";
import notifyMessages, { MessageType } from "./notifyMessages";
import Header from "./components/Header";
import Page from "./components/Page";

const { Content } = Layout;

const styles = {
  root: {
    height: "100vh",
  },
  content: {
    padding: "12px 24px",
  },
};

type Props = {
  tenantName: string;
  currentCode: string;
  isAdmin: boolean;
  flashMessages: string;
  children: React.ReactNode;
};

const Main: React.FC<Props> = ({ tenantName, currentCode, isAdmin, flashMessages, children }) => {
  const setTenantName = useSetTenantName();
  const setIsAdmin = useSetIsAdmin();

  React.useEffect(() => {
    setTenantName(tenantName);
    setIsAdmin(isAdmin);
  }, []);

  React.useEffect(() => {
    if (flashMessages) notifyMessages(JSON.parse(flashMessages) as MessageType);
  }, [flashMessages]);

  return (
    <Layout css={styles.root}>
      <Header tenantName={tenantName} currentCode={currentCode} />
      <Content css={styles.content}>
        <Row justify="center" align="middle">
          {children}
        </Row>
      </Content>
    </Layout>
  );
};

const AppLayout: React.FC<Props> = ({ children, ...params }) => {
  return (
    <ConfigProvider locale={jaJP}>
      <RecoilRoot>
        <RecoilExternalState />
        <Page>
          <Main {...params}>{children}</Main>
        </Page>
      </RecoilRoot>
    </ConfigProvider>
  );
};

export default AppLayout;
