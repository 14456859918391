import React from "react";
import { CSSObject } from "styled-components";
import { Layout, Row, Col, Grid } from "antd";
import { UserIcon, LogoutIcon, CloseIcon } from "~/components/utils/Icons";
import { PRIMARY_MAIN_COLOR } from "~/constants/style";
import FormButton from "~/components/button/FormButton";
import Button from "~/components/button/Button";
import IconText from "~/components/text/IconText";
import DropDownMenuButton from "./DropDownMenuButton";

const styles = {
  header: {
    backgroundColor: PRIMARY_MAIN_COLOR,
    color: "white",
    fontSize: "24px",
    height: "44px",
    lineHeight: "42px",
    padding: 2,
    paddingLeft: 6,
  } as CSSObject,
  rightMenu: {
    fontSize: "20px",
    textAlign: "right",
    marginRight: "8px",
  } as CSSObject,
  closeButton: {
    marginLeft: "10px",
    color: "#FFFFFF",
    "&:hover, &:focus": { color: "#FFFFFF", opacity: "0.8" },
  } as CSSObject,
  logoutButton: {
    marginLeft: "10px",
    color: "#FFFFFF",
    "&:hover, &:focus": { color: "#FFFFFF", opacity: "0.8" },
  } as CSSObject,
};

type Props = {
  tenantName: string;
  currentCode: string;
};

const closeButton = () => {
  return (
    <Button windowClose theme="link" label="閉じる" icon={CloseIcon} css={styles.closeButton} />
  );
};

const logoutButton = (currentCode: string, tenantName: string) => {
  return (
    currentCode && (
      <FormButton
        method="POST"
        url={`/${tenantName}/logout`}
        theme="link"
        icon={LogoutIcon}
        css={styles.logoutButton}
      >
        ログアウト
      </FormButton>
    )
  );
};

const Header: React.FC<Props> = ({ tenantName, currentCode }) => {
  const screens = Grid.useBreakpoint();

  return (
    <Layout.Header css={styles.header}>
      <Row>
        <Col flex="100px">
          Manafy
          {tenantName && <span css={{ marginLeft: "12px" }}>{tenantName}</span>}
        </Col>
        <Col flex="auto" css={styles.rightMenu}>
          {currentCode && <DropDownMenuButton />}
          {currentCode && screens.md && <IconText icon={UserIcon} text={currentCode} />}

          {window.opener ? closeButton() : logoutButton(currentCode, tenantName)}
        </Col>
      </Row>
    </Layout.Header>
  );
};

export default Header;
