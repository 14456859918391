/* eslint-disable */
import React from "react";
import { Space } from "antd";

import Button from "~/components/button/Button";
import { InfoCircleOutlined } from "@ant-design/icons";
import TextInput from "~/components/input/TextInput";
import Message from "~/components/utils/Message";
import { sendMailApi } from "~/api/password_resets_api";
import { dialogConfirm } from "~/utils/dialog";
import { useWebApi } from "~/hooks";
import { notifySuccess, notifyError } from "~/utils/notification";

const MailInput: React.FC<{ onChange: (mail: string) => void }> = props => {
  const { onChange } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value);
  };

  return (
    <Space direction="vertical">
      <p>
        ログイン時に利用しているメールアドレスを入力し、OKボタンを押してください。
        <br />
        入力されたメールアドレスへパスワード再発行の案内をお送りします。
      </p>
      <Message
        type="warning"
        text="メールに記載されているURLの有効期限は1時間です。1時間以内にアクセスできなかった場合は、再度お手続きをお願いします。"
      />
      <TextInput onChange={handleChange} label="メールアドレス" />
    </Space>
  );
};

const ResetPasswordLink: React.FC = () => {
  const dialog = React.useRef();
  const mail = React.useRef("");
  const sendMail = useWebApi(sendMailApi);

  const handleChange = (value: string) => {
    mail.current = value;
  };

  const handleSend = async () => {
    if (!mail.current) {
      notifyError("メールアドレスを入力してください");
      return Promise.reject();
    }
    const response = await sendMail({ email: mail.current });

    if (response.isSuccess) {
      notifySuccess(`${mail.current} へパスワード再発行メールを送信しました`);
      return Promise.resolve();
    }
    notifyError("パスワード再発行メールの送信に失敗しました");
    return Promise.reject();
  };

  const handleClick = () => {
    mail.current = null;

    dialog.current = dialogConfirm({
      title: "パスワード再発行メールの送信",
      body: <MailInput onChange={handleChange} />,
      onOk: handleSend,
    });
  };

  return (
    <Button
      theme="link"
      label="パスワードを忘れた方はこちら"
      icon={InfoCircleOutlined}
      onClick={handleClick}
    />
  );
};

export default ResetPasswordLink;
