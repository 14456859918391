import { useSetWaitingContext, useSetErrorInfoContext } from "~/contexts/PageContext";
import { notifySuccess, notifyError } from "~/utils/notification";
import { ApiResponse } from "~/types/Api";

type ErrorStatus = 400 | 401 | 403 | 404 | 422 | 500;

type Options = {
  /**
   * ロード中にページを触られないようにブロックするか。
   * trueの場合は画面に半透明のマスクがかかり何らかの処理中ということが分かりやすくなる。
   * 2重送信されないようにデフォルトはtrue。
   * 本オプションを使用するには Page コンポーネントの下位コンポーネントで使用する必要がある。 */
  pageBlock?: boolean;
  successMessage?: string;
  setErrors?: (errors: Record<string, any>) => void;
  /** ステータスコード別のエラー処理。指定しない場合はデフォルトの動作となる。 */
  onError?: { [key in ErrorStatus]?: () => void };
};

interface Api {
  (...params: any[]): Promise<ApiResponse>;
}

/** web api を使用する際のエラー処理や処理中マスクをよしなに行うためのhook */
const useWebApi = <T extends Api>(api: T, options: Options = {}): T => {
  const { pageBlock = true, successMessage = null, setErrors = null, onError } = options;
  const setWaiting = useSetWaitingContext();
  const setErrorInfo = useSetErrorInfoContext();

  const webApi = async (...params: any) => {
    setWaiting(pageBlock && true);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const response = await api(...params);
    setWaiting(pageBlock && false);

    // エラー情報が残り続けないようにエラーの有無に関わらずセットする。（エラーがなかった場合はエラー情報がクリアされる）
    if (setErrors) setErrors(response.errorDetails || {});

    if (response.isSuccess) {
      if (successMessage) notifySuccess(successMessage);
    } else {
      const { statusCode, errorCode, error } = response;

      if (onError && onError[statusCode]) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        onError[statusCode]();
      } else if (statusCode === 400 || statusCode === 422 || statusCode === 500) {
        notifyError(error);
      } else if (statusCode === 401 || statusCode === 403 || statusCode === 404) {
        setErrorInfo({ statusCode, message: `[${errorCode}] ${error}` });
      }
    }

    return response;
  };

  return webApi as T;
};

export default useWebApi;
