import * as React from "react";
import styled from "styled-components";
import { Input as AntdInput } from "antd";
import { ClearIcon } from "~/components/utils/Icons";

export interface Props {
  type?: "text" | "password";
  label?: string;
  value?: string | number;
  prefix?: React.ComponentType<any> | null;
  suffix?: React.ComponentType<any> | null;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
  autoComplete?: string;
}

const InnerIcon = styled.span`
  color: rgba(0, 0, 0, 0.25);
`;

const TextInput: React.FC<Props> = ({
  type = "text",
  label,
  prefix: Prefix,
  suffix: Suffix,
  disabled = false,
  onChange = () => {},
  ...others
}) => {
  const InputComponent = type === "password" ? AntdInput.Password : AntdInput;
  const suffixIcon = Suffix ? <ClearIcon onClick={<Suffix />} /> : undefined;
  const prefixIcon = Prefix ? <InnerIcon children={<Prefix />} /> : undefined;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  return (
    <InputComponent
      placeholder={label ? `<${label}>` : undefined}
      prefix={prefixIcon}
      suffix={suffixIcon}
      disabled={disabled}
      onChange={handleChange}
      allowClear
      {...others}
    />
  );
};

TextInput.displayName = "TextInput";

export default TextInput;
