import React from "react";
import AntIcon from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const wrapFontAwesome = (type: IconProp) => {
  return function fontawsame() {
    return <FontAwesomeIcon icon={type} />;
  };
};

// 白抜きの場合は"far", 塗り潰しの場合は"fas"
// e.g.) icon(["far", "square"]);
//
// `<FontAwesomeIcon icon="xx" />` だけでも任意のアイコンを表示できるが、
// サイドメニュー等での表示が崩れるために`<AntIcon>` でラップしている。
const icon = (type: IconProp, name: string) => {
  const comp = ({ style = {}, ...options }: any = {}) => (
    <AntIcon
      component={wrapFontAwesome(type)}
      style={{ verticalAlign: "baseline", ...style } as React.CSSProperties}
      {...options}
    />
  );
  comp.displayName = name;

  return comp;
};

export default icon;
