import React from "react";
import styled, { CSSProp } from "styled-components";

export interface Props {
  text?: string;
  icon: React.ElementType;
  wrapCss?: CSSProp;
  children?: React.ReactNode;
}

const Text = styled.span`
  margin-left: 8px;
`;

const WrapDiv = styled.div`
  display: inline-block;
`;

const IconText: React.FC<Props> = ({ icon: Icon, text, wrapCss, children }) => {
  return (
    <WrapDiv css={wrapCss}>
      <Icon />
      <Text>{text || children}</Text>
    </WrapDiv>
  );
};

IconText.displayName = "IconText";

export default IconText;
