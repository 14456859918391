import React from "react";
import { Col } from "antd";
import IconText from "~/components/text/IconText";
import { MessageIcon, UserIcon, OperationMaintenanceIcon } from "~/components/utils/Icons";

const style = {
  dt: {
    fontSize: "24px",
  },
  dd: {
    fontSize: "16px",
    marginBottom: "36px",
  },
};

type Props = {
  isAdmin: boolean;
  webAuthoritiesPath: string;
};

const ManageMenusIndexPage: React.FC<Props> = ({ isAdmin = false, webAuthoritiesPath }) => {
  return (
    <Col md={10} css={{ "margin-top": "20vh" }}>
      <dl css={style}>
        <dt>
          {/* TODO: href修正後削除 */
          /* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#">
            <IconText icon={UserIcon} text="ユーザー管理" />
          </a>
        </dt>
        <dd>ユーザー情報の閲覧・検索、ユーザーに紐づく通知メッセージの閲覧</dd>

        <dt>
          {/* TODO: href修正後削除 */
          /* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#">
            <IconText icon={MessageIcon} text="メッセージ管理" />
          </a>
        </dt>
        <dd>通知メッセージの閲覧・検索、通知メッセージに紐づくユーザーの閲覧</dd>

        {isAdmin && (
          <>
            <dt>
              <a href={webAuthoritiesPath}>
                <IconText icon={OperationMaintenanceIcon} text="Webログインアカウント管理" />
              </a>
            </dt>
            <dd>本管理機能を利用できるユーザーの設定</dd>
          </>
        )}
      </dl>
    </Col>
  );
};

export default ManageMenusIndexPage;
