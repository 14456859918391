/* eslint-disable */
// @ts-nocheck
import React from "react";
import { createRoot } from "react-dom/client";

import ComponentRegistry from "~/application/ComponentRegistry";

let root = null;

// RailsのViewにReactを描画する
document.addEventListener("mountComponent", e => {
  const container = document.getElementById(e.detail.id);

  const component = React.createElement(
    ComponentRegistry.get(e.detail.component),
    JSON.parse(e.detail.props),
  );

  const layout = JSON.parse(e.detail.layout);
  const layoutWithComponent = React.createElement(ComponentRegistry.get(layout.component), {
    ...layout.props,
    children: component,
  });

  if (root) root.unmount();
  root = createRoot(container);
  root.render(layoutWithComponent);
});
