import icon from "./icon";

// ------------------------------------------------
// Manafy固有(マスタ関連)
// ------------------------------------------------

// マスタ管理
export const MasterMaintenanceIcon = icon("database", "MasterMaintenanceIcon");
// メッセージ
export const MessageIcon = icon("square-envelope", "MessageIcon");
// 授業
export const LessonIcon = icon("book", "LessonIcon");
// 担当授業
export const TeacherLessonIcon = icon("chalkboard-teacher", "TeacherLessonIcon");
// 所属
export const BelongIcon = icon("sitemap", "BelongIcon");
// 大学
export const CollegeIcon = icon("school", "CollegeIcon");
// 教員
export const TeacherIcon = icon("user-tie", "TeacherIcon");
// 教職員所属
export const StaffBelongIcon = icon("user-tag", "StaffBelongIcon");
// 履修
export const StudentLessonIcon = icon("graduation-cap", "StudentLessonIcon");
// マスタ管理
export const OperationMaintenanceIcon = icon("server", "OperationMaintenanceIcon");
// パスワード変更
export const PasswordIcon = icon("lock", "PasswordIcon");
// アカウント管理
export const AccountIcon = icon("user-circle", "AccountIcon");
// 権限
export const AuthorityIcon = icon("ban", "AuthorityIcon");
// ユーザグループ
export const UsersIcon = icon("user-friends", "UsersIcon");
// ユーザ
export const UserIcon = icon("user", "UserIcon");
// 履歴
export const HistoryIcon = icon("history", "HistoryIcon");
// 学生
export const StudentIcon = icon("user-graduate", "StudentIcon");
// 設定
export const SettingsIcon = icon("cog", "SettingsIcon");
// 成績
export const StudentEvaluationIcon = icon("trophy", "StudentEvaluationIcon");
// パスワード一括変更
export const PasswordsIcon = icon("key", "PasswordsIcon");

// ------------------------------------------------
// Manafy固有(管理機能関連)
// ------------------------------------------------

// アカウント切り替え
export const AccountSwitchIcon = icon("user-secret", "AccountSwitchIcon");
// 通知
export const AnnoucementIcon = icon("bullhorn", "AnnoucementIcon");
// ダッシュボード
export const DashboardIcon = icon("tachometer-alt", "DashboardIcon");
// システム設定
export const AppConfigIcon = icon("cogs", "AppConfigIcon");
