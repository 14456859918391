import React from "react";
import { Form as AntdForm, FormInstance, FormProps } from "antd";
import { getCSRFToken } from "~/utils/rails";

type Props = FormProps & {
  method: Method;
  action: string;
  children: React.ReactNode;
};

type Method = "POST" | "GET" | "PUT" | "PATCH" | "DELETE";
type MethodParams = {
  hidden: boolean;
  method: Method;
};

const Form: React.FC<Props> = ({ method, action, children, ...params }) => {
  const [methodParams, setMethodParams] = React.useState<MethodParams>({
    hidden: false,
    method: "POST",
  });

  const antdFormRef = React.createRef<FormInstance>();
  const formRef = React.createRef<HTMLFormElement>();

  const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    antdFormRef.current.submit(); // バリデーション実行
  };

  const onFinish = () => {
    formRef.current.submit();
  };

  React.useEffect(() => {
    switch (method) {
      case "PUT":
      case "PATCH":
      case "DELETE":
        setMethodParams({ hidden: true, method: "POST" });
        return;
      case "POST":
        setMethodParams({ hidden: false, method: "POST" });
        return;
      case "GET":
        setMethodParams({ hidden: false, method: "GET" });
        return;
      default:
        throw new Error("Not Found method");
    }
  }, [method]);

  return (
    <form ref={formRef} method={methodParams.method} action={action} onSubmit={onSubmit}>
      <AntdForm
        validateTrigger={["onChange", "onSubmit"]}
        ref={antdFormRef}
        component="div"
        onFinish={onFinish}
        {...params}
      >
        {methodParams.hidden && <input type="hidden" name="_method" value={method} />}
        <input type="hidden" name="authenticity_token" value={getCSRFToken()} />
        {children}
      </AntdForm>
    </form>
  );
};

export const { useForm } = AntdForm;
export const { useFormInstance } = AntdForm;
export const { useWatch } = AntdForm;
export const FormItem = AntdForm.Item;
export const FormList = AntdForm.List;
export const FormErrorList = AntdForm.ErrorList;
export const FormProvider = AntdForm.Provider;
export { Form };
