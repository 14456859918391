// ユニークが担保されるものがないのでruleを例外的に許可する。React.memoを使用しているのでパフォーマンスにも影響ないはず。
/* eslint-disable react/no-array-index-key */
import React from "react";

import ExternalLink from "~/components/utils/ExternalLink";

export interface Props {
  text: string | null;
}

const replaceUrl = (text: string) => {
  const regex = /(https?:\/\/\S+)/gi;

  return text.split(regex).map((str, i) => {
    if (!regex.test(str)) return str;

    return <ExternalLink url={str} text={str} key={i} />;
  });
};

const MultiLineText: React.FC<Props> = ({ text }) => {
  if (!text) return <span />;

  const lines = text.split("\n");

  return (
    <span>
      {lines.map((line, i) => (
        <React.Fragment key={i}>
          <>{replaceUrl(line)}</>
          <br />
        </React.Fragment>
      ))}
    </span>
  );
};

MultiLineText.displayName = "MultiLineText";

export default React.memo(MultiLineText);
