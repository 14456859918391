import React from "react";
import { Button, Dropdown, Menu, Space } from "antd";
import { MenuIcon } from "~/components/utils/Icons";
import { useIsAdmin } from "~/contexts/GlobalContext";
import { withTenantPath } from "~/utils/url";
import type { MenuProps } from "antd";

const MenuLinks = {
  dummy: () => withTenantPath("/dummy"),
  web_authorities: () => withTenantPath("/manage/web_authorities"),
};

const MenuItems = () => {
  const isAdmin = useIsAdmin();

  const menus: MenuProps["items"] = [
    {
      key: "1",
      label: <a href={MenuLinks.dummy()}>ユーザ管理</a>,
      disabled: window.location.pathname === MenuLinks.dummy(),
    },
    {
      key: "2",
      label: <a href={MenuLinks.dummy()}>メッセージ管理</a>,
      disabled: window.location.pathname === MenuLinks.dummy(),
    },
  ];

  if (isAdmin) {
    menus.push({
      key: "3",
      label: <a href={MenuLinks.web_authorities()}>Webログインアカウント管理</a>,
      disabled: window.location.pathname === MenuLinks.web_authorities(),
    });
  }

  return <Menu items={menus} />;
};

const styles = {
  fontSize: "20px",
  color: "#FFFFFF",
  "&:hover, &:focus": {
    color: "#FFFFFF",
    opacity: "0.8",
  },
};

const DropDownMenuButton: React.FC = () => {
  return (
    <Dropdown overlay={<MenuItems />}>
      <Button type="link" css={styles}>
        <Space>
          <MenuIcon />
          メニュー
        </Space>
      </Button>
    </Dropdown>
  );
};

export default DropDownMenuButton;
