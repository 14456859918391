import React, { useState } from "react";
import { Input } from "antd";
import { CSSObject } from "styled-components";

const styles = {
  floatLabel: {
    position: "relative",
  } as CSSObject,

  label: {
    fontWeight: "normal",
    position: "absolute",
    pointerEvents: "none",
    left: "12px",
    top: "6px",
    transition: "0.2s ease all",
    zIndex: "999",
  } as CSSObject,

  asPlaceholder: {
    color: "gray",
  } as CSSObject,

  asLabel: {
    top: "-11px",
    fontSize: "12px !important",
    background: "white",
    padding: "0 4px",
    marginLeft: "-4px",
  } as CSSObject,
};

const inputComponents = {
  password: Input.Password,
};

type Props = {
  label: string;
  placeholder?: string;
  name: string;
  value?: string;
  type?: keyof typeof inputComponents;
  required?: boolean;
  onChange?: (e: any) => void;
};

const FloatInput: React.FC<Props> = ({
  label,
  value,
  placeholder,
  type,
  required,
  name,
  onChange,
}) => {
  const [focus, setFocus] = useState<boolean>(false);
  const [localValue, setLocalValue] = useState(value);

  const isOccupied = focus || (localValue && localValue.length !== 0);

  const labelStyle = isOccupied
    ? { ...styles.label, ...styles.asLabel }
    : { ...styles.label, ...styles.asPlaceholder };

  const requiredMark = required ? <span className="text-danger">*</span> : null;

  const InputComponent = inputComponents[type] || Input;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
    if (onChange) onChange(e);
  };

  return (
    <div css={styles.floatLabel} onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      <InputComponent onChange={handleChange} type={type} defaultValue={value} name={name} />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label css={labelStyle}>
        {isOccupied ? label : placeholder || label} {requiredMark}
      </label>
    </div>
  );
};

export default FloatInput;
