import * as React from "react";
// 画面の右上にポップアップで通知する。
import { Modal, notification } from "antd";

import MultiLineText from "~/components/text/MultiLineText";

type EventHandler = (e: React.MouseEvent<any>) => void;

const noop = () => {};

export const confirm = (
  description: string,
  onOk: EventHandler,
  onCancel: EventHandler = noop,
  message = "確認",
): void => {
  Modal.confirm({
    title: message,
    content: <MultiLineText text={description} />,
    onOk,
    onCancel,
    okText: "OK",
    cancelText: "Cancel",
  });
};

// `duration: 0` を指定すると自動消滅しないようになる
type Notify = (description: string | React.ReactNode, message?: string, duration?: number) => void;
const DEFAULT_DURATION = 4.5;

export const notifySuccess: Notify = (
  description,
  message = undefined,
  duration = DEFAULT_DURATION,
) => {
  notification.success({ message, description, duration });
};

export const notifyInfo: Notify = (
  description,
  message = undefined,
  duration = DEFAULT_DURATION,
) => {
  notification.info({ message, description, duration });
};

export const notifyWarning: Notify = (
  description,
  message = undefined,
  duration = DEFAULT_DURATION,
) => {
  notification.warning({ message, description, duration });
};

export const notifyError: Notify = (
  description,
  message = "エラー",
  duration = DEFAULT_DURATION,
) => {
  notification.error({ message, description, duration });
};
