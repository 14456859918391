import React from "react";
import { Checkbox, Col, Row } from "antd";
import FloatInput from "~/components/input/FloatInput";
import Button from "~/components/button/Button";
import { Form, FormItem } from "~/components/form/Form";
import ResetPasswordLink from "./components/ResetPasswordLink";

type Props = {
  path: string;
  title: string;
};

const SessionNewPage: React.FC<Props> = ({ path, title }) => {
  return (
    <Col md={10} lg={8} xl={6} xxl={5} css={{ "margin-top": "20vh" }}>
      <Form
        action={path}
        method="POST"
        name="basic"
        initialValues={{ remember: false }}
        autoComplete="off"
      >
        <Row justify="center" align="middle" css={{ marginBottom: "4px" }}>
          <img src="/ManafyIcon256.png" alt="manafy" css={{ width: "28px", borderRadius: "6px" }} />
          <span css={{ fontSize: "18px", marginLeft: "6px", color: "#686868" }}>Manafy</span>
        </Row>
        <Row justify="center" align="middle" css={{ marginBottom: "18px" }}>
          <span css={{ fontSize: "32px" }}>{title}</span>
        </Row>

        <FormItem
          name="email"
          rules={[{ required: true, message: "メールアドレスを入力してください" }]}
        >
          <FloatInput label="メールアドレス" name="user[email]" />
        </FormItem>

        <FormItem
          name="password"
          rules={[{ required: true, message: "パスワードを入力してください" }]}
        >
          <FloatInput type="password" label="パスワード" name="user[password]" />
        </FormItem>

        <FormItem name="remember_me" valuePropName="checked">
          <Checkbox name="user[remember_me]">ログイン状態を保存する</Checkbox>
        </FormItem>

        <FormItem>
          <Button theme="primary" label="ログイン" css={{ width: "100%" }} htmlType="submit" />
        </FormItem>
        <FormItem css={{ textAlign: "center" }}>
          <ResetPasswordLink />
        </FormItem>
      </Form>
    </Col>
  );
};

export default SessionNewPage;
