import * as Turbo from "@hotwired/turbo";
import "~/patches/fast-refresh-fix";
import "~/application/componentRegister";

// 以降、各レイアウトインポート
import "~/layouts/AppLayout";

// 以降、各ページインポート
import "~/pages/sessions";
import "~/pages/password_resets";
import "~/pages/manage/menus";
import "~/pages/manage/web_authorities";

Turbo.start();
require.context("images", true);
