import React from "react";
import { Row, Col } from "antd";
import type { ColumnsType } from "antd/lib/table";
import DataTable, { useTextFilter, BaseDataType } from "~/components/table/DataTable";
import Anchor from "~/components/anchor/Anchor";
import FormButton from "~/components/button/FormButton";
import { indexApi } from "~/api/manage/web_authorities_api";

interface DataType extends BaseDataType {
  code: string;
  type: string;
  email: string;
  web_role: string;
}

type Props = {
  newPath: string;
};

const ManageUserAuthoritiesIndexPage: React.FC<Props> = ({ newPath }) => {
  const { getColumnSearchProps } = useTextFilter();

  const columns: ColumnsType<DataType> = [
    {
      title: "コード",
      dataIndex: "code",
      sorter: true,
      width: "150px",
      ...getColumnSearchProps("code"),
    },
    {
      title: "Webログイン権限",
      dataIndex: "web_role",
      sorter: true,
      width: "200px",
      filters: [
        { text: "一般権限", value: "normal" },
        { text: "管理権限", value: "admin" },
      ],
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
      responsive: ["md"],
      ...getColumnSearchProps("email"),
    },
    {
      title: "",
      dataIndex: "edit_path",
      width: "100px",
      align: "center",
      render: (editPath: string) => {
        return <Anchor label="編集" url={editPath} theme="primary" />;
      },
    },
    {
      title: "",
      dataIndex: "destroy_path",
      width: "100px",
      align: "center",
      render: (destroyPath: string) => {
        return (
          <FormButton
            method="DELETE"
            confirm={{ position: "top", text: "本当に削除してもよろしいですか？" }}
            url={destroyPath}
          >
            削除
          </FormButton>
        );
      },
    },
  ];

  return (
    <Col md={24} lg={20} xl={16}>
      <Row>
        <Col flex="300px">
          <h1 css={{ marginRight: "auto" }}>Webログインアカウント管理</h1>
        </Col>
        <Col flex="auto">
          <Anchor label="新規作成" url={newPath} theme="primary" />
        </Col>
      </Row>

      <DataTable<DataType>
        api={indexApi}
        columns={columns}
        defaultSort={{ key: "web_role", dir: "desc" }}
      />
    </Col>
  );
};

export default ManageUserAuthoritiesIndexPage;
