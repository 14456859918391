import React from "react";
import { Popconfirm } from "antd";
import { getCSRFToken } from "~/utils/rails";
import { LINK_BUTTON_STYLE, DEFAULT_BUTTON_STYLE, PRIMARY_BUTTON_STYLE } from "~/constants/style";
import IconText from "~/components/text/IconText";
import MultiLineText from "~/components/text/MultiLineText";
import PopupPosition from "~/types/PopupPosition";

type Popup = {
  position: PopupPosition;
  text: React.ReactNode;
};

type Method = "POST" | "GET" | "PUT" | "PATCH" | "DELETE";

export type Props = {
  method: Method;
  url?: string;
  formTarget?: "_blank" | "_self" | "_parent" | "_top";
  theme?: "link" | "default" | "primary";
  icon?: React.ComponentType<any>;
  className?: string; // styled-components の css prop を使うと自動的にクラス名を受け取る
  confirm?: Popup;
  children: string | React.ReactNode;
};

const getCustomStyle = (theme: string) => {
  switch (theme) {
    case "link":
      return LINK_BUTTON_STYLE;
    case "default":
      return DEFAULT_BUTTON_STYLE;
    case "primary":
      return PRIMARY_BUTTON_STYLE;
    default:
      return {};
  }
};

const getMethodParam = (method: string) => {
  switch (method) {
    case "PUT":
    case "PATCH":
    case "DELETE":
      return { hidden: true, method: "POST" };
    case "POST":
      return { hidden: false, method: "POST" };
    case "GET":
      return { hidden: false, method: "GET" };
    default:
      throw new Error(`Not Found ${method}`);
  }
};

const FormButton: React.FC<Props> = ({
  method,
  url,
  formTarget,
  theme = "default",
  icon,
  className,
  confirm,
  children,
}) => {
  const formRef = React.createRef<HTMLFormElement>();
  const themeStyle = getCustomStyle(theme);
  const methodParam = getMethodParam(method);

  const handleClick = () => {
    formRef.current.submit();
  };

  const button = (
    <form
      data-turbo="false"
      ref={formRef}
      method={methodParam.method}
      action={url || "#"}
      css={{ display: "inline-block" }}
      target={formTarget}
    >
      <input type="hidden" name="authenticity_token" value={getCSRFToken()} />
      {methodParam.hidden && <input type="hidden" name="_method" value={method} />}

      <button
        type="button"
        css={themeStyle}
        className={className}
        onClick={() => {
          if (confirm) return;
          handleClick();
        }}
      >
        {icon ? <IconText icon={icon}>{children}</IconText> : children}
      </button>
    </form>
  );

  if (confirm) {
    const title =
      typeof confirm.text === "string" ? <MultiLineText text={confirm.text} /> : confirm.text;
    return (
      <Popconfirm
        title={title}
        placement={confirm.position}
        onConfirm={handleClick}
        onCancel={() => {}}
        okText="OK"
        cancelText="Cancel"
      >
        {button}
      </Popconfirm>
    );
  }

  return button;
};

export default FormButton;
