import React from "react";
import { Col } from "antd";
import FloatInput from "~/components/input/FloatInput";
import Button from "~/components/button/Button";
import { Form, FormItem } from "~/components/form/Form";
import Message from "~/components/utils/Message";

type Props = {
  path: string;
  errorMessage: string;
};

const PasswordResetsEditPage: React.FC<Props> = ({ path, errorMessage }) => {
  return (
    <Col md={8} css={{ "margin-top": "20vh" }}>
      {errorMessage && (
        <Message type="error" text={errorMessage} style={{ marginBottom: "10px" }} />
      )}
      <Form
        action={path}
        method="PATCH"
        name="basic"
        initialValues={{ remember: false }}
        autoComplete="off"
      >
        <FormItem
          name="password"
          rules={[{ required: true, message: "パスワードを入力してください" }]}
        >
          <FloatInput
            type="password"
            label="新しいパスワード(半角英数・記号のみ、8文字以上)"
            name="user[password]"
          />
        </FormItem>

        <FormItem
          name="password_confirmation"
          rules={[{ required: true, message: "パスワードを入力してください" }]}
        >
          <FloatInput type="password" label="新しいパスワード" name="user[password_confirmation]" />
        </FormItem>

        <FormItem>
          <Button theme="primary" label="送信する" css={{ width: "100%" }} htmlType="submit" />
        </FormItem>
      </Form>
    </Col>
  );
};

export default PasswordResetsEditPage;
