import { notification } from "antd";

const flashToNotificationType = {
  notice: "success",
  warning: "warning",
  alert: "warning",
  error: "error",
};

const showNotification = (type: NotificationType, message: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  notification[type]({
    message,
    description: "", // 必須項目なので空文字で回避
  });
};

type NotificationType = typeof flashToNotificationType[keyof typeof flashToNotificationType];
type FlashType = keyof typeof flashToNotificationType;
export type MessageType = Array<[FlashType, string]>;
type Notify = (messages: MessageType) => void;

const notifyMessages: Notify = messages => {
  messages.forEach(([type, message]) => {
    const notificationType = flashToNotificationType[type];
    showNotification(notificationType, message);
  });
};

export default notifyMessages;
