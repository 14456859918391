import React from "react";
import { Col } from "antd";
import FloatInput from "~/components/input/FloatInput";
import FloatSelectField from "~/components/select/FloatSelectField";
import Button from "~/components/button/Button";
import Anchor from "~/components/anchor/Anchor";
import { Form, FormItem } from "~/components/form/Form";

export type Props = {
  backPath: string;
  path: string;
  method: "PUT" | "POST";
  code: string;
  email: string;
  webRole: string;
  primaryBtnLabel: string;
  errorMessages?: Record<string, any>;
};

const ManageWebAuthoritiesForm: React.FC<Props> = ({
  backPath,
  path,
  method,
  code,
  email,
  webRole,
  primaryBtnLabel,
  errorMessages = {},
}) => {
  return (
    <Col md={8} css={{ "margin-top": "20vh" }}>
      <Form action={path} method={method} autoComplete="off">
        <FormItem
          name="user[code]"
          initialValue={code}
          rules={[{ required: true, message: "コードを入力してください" }]}
          {...errorMessages.code}
        >
          <FloatInput label="コード" name="user[code]" />
        </FormItem>

        <FormItem
          name="user[email]"
          initialValue={email}
          rules={[{ required: true, message: "メールアドレスを入力してください" }]}
          {...errorMessages.email}
        >
          <FloatInput label="メールアドレス" name="user[email]" />
        </FormItem>

        <FormItem
          name="user[web_role]"
          initialValue={webRole}
          rules={[{ required: true, message: "権限を選択してください" }]}
          {...errorMessages.web_role}
        >
          <FloatSelectField
            label="権限"
            name="user[web_role]"
            items={[
              { label: "一般権限", value: "normal" },
              { label: "管理権限", value: "admin" },
            ]}
          />
        </FormItem>

        <FormItem css={{ display: "flex", justifyContent: "space-between" }}>
          <Anchor label="戻る" url={backPath} theme="default" css={{ width: "100px" }} />
          <Button
            theme="primary"
            label={primaryBtnLabel}
            htmlType="submit"
            css={{ float: "right", width: "100px" }}
          />
        </FormItem>
      </Form>
    </Col>
  );
};

export default ManageWebAuthoritiesForm;
