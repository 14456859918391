import * as React from "react";
import { Alert } from "antd";
import { CSSObject } from "styled-components";

import MultiLineText from "~/components/text/MultiLineText";

export interface Props {
  type: "info" | "success" | "warning" | "error";
  text: string | React.ReactNode;
  description?: string;
  closable?: boolean;
  action?: React.ReactNode;
  style?: CSSObject;
}

const Message: React.FC<Props> = ({ type, text, description, closable = true, action, style }) => {
  const message = typeof text === "string" ? <MultiLineText text={text} /> : text;

  return (
    <Alert
      message={message}
      description={description}
      type={type}
      showIcon
      closable={closable}
      action={action}
      css={style}
    />
  );
};

Message.displayName = "Message";

export default Message;
