import React from "react";
import { Button as AntdButton, Popconfirm, ButtonProps } from "antd";
import { PRIMARY_BUTTON_STYLE, LINK_BUTTON_STYLE } from "~/constants/style";
import MultiLineText from "~/components/text/MultiLineText";
import Tooltip from "~/components/utils/Tooltip";
import PopupPosition from "~/types/PopupPosition";

interface Popup {
  position: PopupPosition;
  text: React.ReactNode;
}

export type ButtonSize = "small" | "middle" | "large";

export interface Props {
  label?: React.ReactNode;
  icon?: React.ComponentType<any> | null;
  confirm?: Popup | null;
  /** ボタンの使用可否。truthy な値の場合は使用不可となる。またテキストを与えた場合は使用不可となるのに加えてボタンにホバーした際にそのテキストがツールチップで表示される。 */
  disabled?: boolean | string;
  ghost?: boolean;
  loading?: boolean;
  tooltip?: string | Popup;
  onClick?: (event: any) => void;
  size?: ButtonSize;
  className?: string; // styled-components の css prop を使うと自動的にクラス名を受け取る
  theme?: "link" | "primary" | "ghost" | "dashed" | "danger" | "default";
  windowClose?: boolean;
  htmlType?: ButtonProps["htmlType"];
}

type ButtonType = {
  type: "link" | "primary" | "ghost" | "dashed" | "default";
  danger?: boolean;
};

const noop = () => {};

const getCustomStyle = (theme: string) => {
  switch (theme) {
    case "primary":
      return PRIMARY_BUTTON_STYLE;
    case "link":
      return LINK_BUTTON_STYLE;
    default:
      return {};
  }
};

const handleClick = (
  e: any,
  onClick: (event: any) => void,
  confirm: Popup | null,
  windowClose: boolean,
) => {
  if (confirm) return;
  onClick(e);
  if (windowClose) window.close();
};

const Button: React.FC<Props> = ({
  label = "",
  onClick = noop,
  icon: Icon,
  confirm,
  disabled = false,
  ghost = false,
  loading = false,
  tooltip,
  theme = "default",
  size = "middle",
  className,
  windowClose,
  ...others
}) => {
  const buttonType: ButtonType =
    theme === "danger" ? { type: "primary", danger: true } : { type: theme };
  const themeStyle = getCustomStyle(buttonType.type);
  const button = (
    <AntdButton
      onClick={e => {
        handleClick(e, onClick, confirm, windowClose);
      }}
      disabled={!!disabled}
      ghost={ghost}
      loading={loading}
      size={size}
      css={themeStyle}
      icon={Icon && <Icon />}
      className={className}
      {...buttonType}
      {...others}
    >
      {label}
    </AntdButton>
  );

  if (confirm) {
    const title =
      typeof confirm.text === "string" ? <MultiLineText text={confirm.text} /> : confirm.text;
    return (
      <Popconfirm
        title={title}
        placement={confirm.position}
        onConfirm={onClick}
        onCancel={noop}
        okText="OK"
        cancelText="Cancel"
      >
        {button}
      </Popconfirm>
    );
  }
  if (tooltip) {
    const tooltipProps = typeof tooltip === "string" ? { text: tooltip } : tooltip;
    return <Tooltip {...tooltipProps}>{button}</Tooltip>;
  }
  if (typeof disabled === "string") {
    return <Tooltip text={disabled}>{button}</Tooltip>;
  }

  return button;
};

export default Button;
