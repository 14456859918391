import React from "react";
import { Tooltip as AntdTooltip } from "antd";

import PopupPosition from "~/types/PopupPosition";

export interface Props {
  text: React.ReactNode;
  position?: PopupPosition;
  children: any;
}

const Tooltip: React.FC<Props> = ({ text, position = "top", children }) => (
  <AntdTooltip title={text} placement={position}>
    {children}
  </AntdTooltip>
);

export default Tooltip;
