// `recoil` は React Context API 依存であり、Reactコンポーネント外部から値を参照・更新できない問題がある
// そこで、`recoil-nexus` を導入し、コンポーネント外部から値を参照できるようにした
// 本拡張は `recoil-nexus` のラッパーであり、将来的に recoil に `recoil-nexus` がマージされた際に修正箇所を減らすためのもの
// https://github.com/facebookexperimental/Recoil/pull/1826
//
// !!! 注意点：値を更新しても再レンダリングされない !!!
//
import RecoilNexus, { getRecoil, setRecoil } from "recoil-nexus";
import { atom as OriginalAtom, RecoilValue, RecoilState } from "recoil";

export const useRecoilValue = <T = any>(atom: RecoilValue<T>): T => {
  return getRecoil(atom);
};

export const useSetRecoilState = <T = any>(
  atom: RecoilState<T>,
): ((valOrUpdater: T | ((currVal: T) => T)) => void) => {
  return (valOrUpdater: T | ((currVal: T) => T)): void => {
    setRecoil(atom, valOrUpdater);
  };
};

export const atom = OriginalAtom;

export const RecoilExternalState = RecoilNexus;
