import icon from "./icon";

// ------------------------------------------------
// 操作系
// ------------------------------------------------

// 追加
export const AddIcon = icon("plus", "AddIcon");
// 更新
export const RefreshIcon = icon("sync", "RefreshIcon");
// リセット
export const ResetIcon = icon("undo", "ResetIcon");
// 編集
export const EditIcon = icon("pencil-alt", "EditIcon");
// 削除
export const DeleteIcon = icon("trash", "DeleteIcon");
// 検索
export const SearchIcon = icon("search", "SearchIcon");
// プレビュー
export const PreviewIcon = icon("eye", "PreviewIcon");
// 保存
export const SaveIcon = icon("save", "SaveIcon");
// 確定, OK
export const DoneIcon = icon("check", "DoneIcon");
// クリア
export const ClearIcon = icon("times", "ClearIcon");
// 閉じる
export const CloseIcon = icon("xmark", "CloseIcon");
// コピー
export const CopyIcon = icon("clone", "CopyIcon");
// 操作
export const OperationIcon = icon("th-large", "OperationIcon");
// ダウンロード
export const DownloadIcon = icon("cloud-download-alt", "DownloadIcon");
// アップロード
export const UploadIcon = icon("cloud-upload-alt", "UploadIcon");
// メニューオープン
export const MenuOpenIcon = icon("indent", "MenuOpenIcon");
// メニュークローズ
export const MenuCloseIcon = icon("outdent", "MenuCloseIcon");
// ジャンプ
export const JumpIcon = icon("arrows-alt-v", "JumpIcon");
// テーブル(表)
export const TableIcon = icon("table", "TableIcon");
// 集計
export const SummarizeIcon = icon("calculator", "SummarizeIcon");
