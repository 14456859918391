import React from "react";
import IconText from "~/components/text/IconText";
import { DEFAULT_BUTTON_STYLE, PRIMARY_BUTTON_STYLE, LINK_BUTTON_STYLE } from "~/constants/style";

type Label =
  | { label: string; children?: undefined }
  | { label?: undefined; children: string | React.ReactNode };

export type Props = {
  url?: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
  icon?: React.ComponentType<any>;
  theme?: "link" | "primary" | "default";
  className?: string; // styled-components の css prop を使うと自動的にクラス名を受け取る
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
} & Label;

const getCustomStyle = (theme: string) => {
  switch (theme) {
    case "link":
      return LINK_BUTTON_STYLE;
    case "default":
      return DEFAULT_BUTTON_STYLE;
    case "primary":
      return PRIMARY_BUTTON_STYLE;
    default:
      return {};
  }
};

const Anchor: React.FC<Props> = ({
  url,
  label,
  target,
  icon,
  theme = "link",
  className,
  onClick,
  children,
}) => {
  const themeStyle = getCustomStyle(theme);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!onClick) return;
    e.preventDefault();
    onClick(e);
  };

  const name = label || children;

  return (
    <a href={url} target={target} className={className} css={themeStyle} onClick={handleClick}>
      {icon ? <IconText icon={icon}>{name}</IconText> : name}
    </a>
  );
};

export default Anchor;
