// このファイルではFont AwesomeのアイコンとManafy上の意味の紐付けを行なっています。
// 原則としてこのファイル以外でFontAwesomeIconをimportするのは禁止です。
//
// 理由
// * 同じ意味であるはずのアイコンが画面によってバラバラになってしまうことを防止する。
// * 同じ形のアイコンをバラバラの意味で使ってしまわないように。
// * iconセットを乗り換える場合でも容易にするため。
// * iconセットがアップデートされた際に名称が変わる場合があるため、そういったものを一覧で発見しやすくするため。(storybook)
//
// アイコンを選ぶときは以下のページから選んでください。(無料版のみが表示されます)
// https://fontawesome.com/icons?d=listing&s=regular,solid&m=free
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

library.add(fas, far);

export * from "./actions";
export * from "./pictograms";
export * from "./manafy";
