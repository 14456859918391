import ComponentRegistry from "~/application/ComponentRegistry";
import ManageWebAuthoritiesIndexPage from "./ManageWebAuthoritiesIndexPage";
import ManageWebAuthoritiesEditPage from "./ManageWebAuthoritiesEditPage";
import ManageWebAuthoritiesNewPage from "./ManageWebAuthoritiesNewPage";

ComponentRegistry.register({
  ManageWebAuthoritiesIndexPage,
  ManageWebAuthoritiesEditPage,
  ManageWebAuthoritiesNewPage,
});
