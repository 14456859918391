import React, { useState } from "react";
import { CSSObject } from "styled-components";
import SelectField, { Props } from "~/components/select/SelectField";

const styles = {
  floatLabel: {
    position: "relative",
  } as CSSObject,

  label: {
    fontWeight: "normal",
    position: "absolute",
    pointerEvents: "none",
    left: "12px",
    top: "6px",
    transition: "0.2s ease all",
    zIndex: "999",
  } as CSSObject,

  asPlaceholder: {
    color: "gray",
  } as CSSObject,

  asLabel: {
    top: "-11px",
    fontSize: "12px !important",
    background: "white",
    padding: "0 4px",
    marginLeft: "-4px",
  } as CSSObject,
};

type FloatProps = {
  label: string;
  required?: boolean;
};

const FloatSelectField: React.FC<Props & FloatProps> = ({
  label,
  required,
  placeholder,
  value,
  onChange,
  ...others
}) => {
  const [focus, setFocus] = useState<boolean>(false);
  const [localValue, setLocalValue] = useState(value);

  const isOccupied = focus || (localValue && String(localValue).length !== 0);

  const labelStyle = isOccupied
    ? { ...styles.label, ...styles.asLabel }
    : { ...styles.label, ...styles.asPlaceholder };

  const requiredMark = required ? <span className="text-danger">*</span> : null;

  const handleChange = (val: string | string[]) => {
    setLocalValue(val);
    if (onChange) onChange(val);
  };

  return (
    <div css={styles.floatLabel} onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      <SelectField onChange={handleChange} value={value} {...others} />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label css={labelStyle}>
        {isOccupied ? label : placeholder || label} {requiredMark}
      </label>
    </div>
  );
};

export default FloatSelectField;
