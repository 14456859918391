import React from "react";
import { Spin } from "antd";

export interface Props {
  blocking: boolean;
  children: React.ReactNode;
}

const BlockUI: React.FC<Props> = ({ blocking, children }) => (
  <Spin tip="しばらくお待ちください..." spinning={blocking} children={children} />
);

BlockUI.displayName = "BlockUI";

export default BlockUI;
