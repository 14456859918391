import React from "react";
import { Modal } from "antd";

import { ClearIcon, DoneIcon, WarningIcon } from "~/components/utils/Icons";

type EventHandler = (e: React.MouseEvent<any>) => void;

type DialogParams = {
  title?: string;
  body: string | React.ReactElement;
  onOk: EventHandler;
  onCancel?: EventHandler;
};

const noop = () => {};

const cancelText = (
  <span>
    <ClearIcon />
    &nbsp;キャンセル
  </span>
);

const okText = (
  <span>
    <DoneIcon />
    &nbsp;OK
  </span>
);

export const dialogConfirm = (params: DialogParams): any => {
  const { title = "確認", body, onOk, onCancel = noop } = params;

  return Modal.confirm({
    title,
    content: body,
    icon: <WarningIcon />,
    onOk,
    onCancel,
    cancelText,
    okText,
    cancelButtonProps: { type: "primary", ghost: true },
  });
};
