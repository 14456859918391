export const PRIMARY_MAIN_COLOR = "#467FC6";

export const LINK_BUTTON_STYLE = {
  color: PRIMARY_MAIN_COLOR,
  "text-decoration": "none",
  "background-color": "transparent",
  outline: "none",
  cursor: "pointer",
  transition: "color 0.3s",
  textAlign: "center",
  border: "none",
  "&:hover, &:focus": {
    color: PRIMARY_MAIN_COLOR,
    "text-decoration": "underline",
  },
};

export const DEFAULT_BUTTON_STYLE = {
  cursor: "pointer",
  color: "#000000d9",
  border: "1px solid transparent",
  borderRadius: "2px",
  borderColor: "#d9d9d9",
  background: "#fff",
  textShadow: "0 -1px 0 rgba(0, 0, 0, 12%)",
  boxShadow: "0 2px #0000000b",
  "-webkit-appearance": "button",
  outline: 0,
  touchAction: "manipulation",
  textAlign: "center",
  height: "32px",
  padding: "4px 15px",
  fontSize: "14px",
  transition: "all .3s cubic-bezier(.645,.045,.355,1)",
  lineHeight: "1.5715",
  display: "inline-block",
  fontWeight: 400,

  "&:hover, &:focus": {
    color: PRIMARY_MAIN_COLOR,
    borderColor: PRIMARY_MAIN_COLOR,
    backgroundColor: "#fff",
    textDecoration: "none",
    opacity: 0.9,
  },
};

export const PRIMARY_BUTTON_STYLE = {
  cursor: "pointer",
  color: "#fff",
  border: "1px solid transparent",
  borderRadius: "2px",
  borderColor: PRIMARY_MAIN_COLOR,
  background: PRIMARY_MAIN_COLOR,
  textShadow: "0 -1px 0 rgba(0, 0, 0, 12%)",
  boxShadow: "0 2px #0000000b",
  "-webkit-appearance": "button",
  outline: 0,
  touchAction: "manipulation",
  textAlign: "center",
  height: "32px",
  padding: "4px 15px",
  fontSize: "14px",
  transition: "all .3s cubic-bezier(.645,.045,.355,1)",
  lineHeight: "1.5715",
  display: "inline-block",
  fontWeight: 400,
  "&:hover, &:focus": {
    color: "#fff",
    backgroundColor: PRIMARY_MAIN_COLOR,
    textDecoration: "none",
    opacity: 0.9,
  },
};
