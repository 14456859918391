import React from "react";
import _ from "lodash";
import { CSSObject } from "styled-components";
import { Table } from "antd";
import type { TablePaginationConfig, ColumnsType, ColumnType } from "antd/lib/table";
import type { FilterValue, SorterResult } from "antd/lib/table/interface";
import type { FetchParams, ApiResponse } from "~/types/Api";
import { useWebApi } from "~/hooks";

export interface BaseDataType {
  id: number;
}

type DefaultSortType = { key: string; dir: "asc" | "desc" };
type ResponseType = {
  results: Array<Record<string, any>>;
  total_count: number;
};

interface Props<T> {
  api: (...params: any[]) => Promise<ApiResponse<ResponseType>>;
  columns: ColumnsType<T>;
  defaultSort?: DefaultSortType;
  styles?: CSSObject;
  bodyMinHeight?: string; // テーブルの縦幅調整
}

const convertSortDirAntdToRails = (direction: "descend" | "ascend") =>
  direction === "descend" ? "desc" : "asc";
const convertSortDirRailsToAntd = (direction: "asc" | "desc") =>
  direction === "desc" ? "descend" : "ascend";

const addDefaultSortParams = <T extends BaseDataType>(
  columns: ColumnsType<T>,
  defaultSort: DefaultSortType,
) => {
  return _.transform(
    columns,
    (result, column: ColumnType<T>) => {
      if (column.dataIndex === defaultSort.key) {
        // eslint-disable-next-line no-param-reassign
        column.defaultSortOrder = convertSortDirRailsToAntd(defaultSort.dir);
      }
      result.push(column);
    },
    [],
  ) as ColumnsType;
};

const DataTable = <T extends BaseDataType>({
  api,
  columns,
  defaultSort,
  styles,
  bodyMinHeight = "76vh",
}: Props<T>) => {
  const fetchApi = useWebApi(api, { pageBlock: false });
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState<TablePaginationConfig>({
    showSizeChanger: false,
    current: 1,
    pageSize: 50,
  });

  const loadData = async (params: FetchParams) => {
    setLoading(true);
    const response = await fetchApi(params);
    setData(response.data.results);
    setLoading(false);
    setPagination({
      ...pagination,
      current: params.page,
      total: response.data.total_count,
    });
  };

  React.useEffect(() => {
    loadData({ sort: defaultSort, page: pagination.current, condition: {} }).catch(() => {});
  }, []);

  const handleTableChange = (
    newPagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<any>,
  ) => {
    loadData({
      sort: { key: sorter.field, dir: convertSortDirAntdToRails(sorter.order) },
      page: newPagination?.current,
      condition: { ...filters },
    }).catch(() => {});
  };

  const columnsResult = addDefaultSortParams<T>(columns, defaultSort);

  return (
    <Table
      css={{
        th: { whiteSpace: "nowrap" },
        ".ant-table-filter-trigger": { fontSize: "16px" },
        ...styles,
      }}
      columns={columnsResult}
      rowKey={(record: T) => record.id}
      dataSource={data}
      sortDirections={["descend", "ascend"]}
      pagination={pagination}
      loading={loading}
      onChange={handleTableChange}
      scroll={{ y: bodyMinHeight }}
    />
  );
};

DataTable.displayName = "DataTable";

export default DataTable;
