import React from "react";

export interface Props {
  url: string;
  text?: string;
  children?: React.ReactNode;
}

const ExternalLink: React.FC<Props> = ({ url, text, children }) => (
  <a href={url} target="_blank" rel="noopener noreferrer">
    {text || children}
  </a>
);

ExternalLink.displayName = "ExternalLink";

export default ExternalLink;
