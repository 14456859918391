import * as React from "react";
import { Select, SelectProps } from "antd";

import { isBlank } from "~/utils/lodash";

const { Option } = Select;

interface Item {
  value: string | number;
  /** 選択アイテムの表示文字列 */
  label: string;
  /** ドロップダウンリスト上の表示文字列、省略した場合は label と同じになる。 */
  menuLabel?: React.ReactNode;
  disabled?: boolean;
}

type Value = null | string | number | string[] | number[];

export interface Props {
  name?: string;
  placeholder?: string;
  value?: Value;
  includeBlank?: boolean;
  items: Item[];
  onChange?: (value: string | string[]) => void;
  style?: React.CSSProperties | null;
  disabled?: boolean;
  filterOption?: SelectProps["filterOption"];
  optionFilterProp?: string;
  open?: boolean;
  showSearch?: boolean;
  size?: "small" | "middle" | "large";
  multiple?: boolean;
}

const menuItems = (items: Item[]) =>
  items.map(({ value, label, menuLabel, disabled = false }) => (
    <Option key={value} value={value} title={label} disabled={disabled}>
      {menuLabel || label}
    </Option>
  ));

const selectHtmlTags = (name: string, multiple: boolean, selectedValue: string | string[]) => {
  if (!selectedValue) return null;

  if (multiple) {
    return (
      <>
        {(selectedValue as []).map(val => {
          const arrayName = `${name}[]`;
          return (
            <input type="text" name={arrayName} defaultValue={val} style={{ display: "none" }} />
          );
        })}
      </>
    );
  }

  return <input type="text" name={name} defaultValue={selectedValue} style={{ display: "none" }} />;
};

const SelectField: React.FC<Props> = props => {
  const [selectedValue, setSelectedValue] = React.useState<string | string[]>(null);
  const {
    name,
    placeholder,
    value,
    items,
    includeBlank,
    onChange = () => {},
    open,
    multiple,
    filterOption,
    ...others
  } = props;
  // Antdの仕様上、未選択はundefinedでないといけない(空文字やnullはだめ)。空文字やnullがデータのkeyとして使われることはないという前提
  const valueProp = isBlank(value) ? undefined : value;
  const [syncValue, setSyncValue] = React.useState(valueProp);

  const handleChange = (val: string | string[]) => {
    setSyncValue(val);
    onChange(val);
    setSelectedValue(val);
  };

  // 以下のように Radio と組み合わせて使用した場合などに、イベントが伝搬してしまいプルダウンが開けない状態となるので対策。
  // <Radio.Group >
  //   <Radio>foo</Radio>
  //   <Radio>other<SelectField /></Radio>
  // </Radio.Group>
  const dummyHandler = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <>
      <Select
        onChange={handleChange}
        placeholder={placeholder ? `<${placeholder}>` : ""}
        style={{ width: "100%" }}
        allowClear={includeBlank}
        defaultActiveFirstOption={!includeBlank}
        defaultOpen={open}
        notFoundContent="データがありません"
        value={syncValue}
        mode={multiple ? "multiple" : undefined}
        filterOption={filterOption}
        optionLabelProp="title"
        onClick={dummyHandler}
        {...others}
      >
        {menuItems(items)}
      </Select>
      {selectHtmlTags(name, multiple, selectedValue)}
    </>
  );
};

SelectField.displayName = "SelectField";

export default SelectField;
