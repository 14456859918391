import _ from "lodash";

// lodash.isEmpty では数値はtrueと判別されるので、rubyライクな挙動を実装
export const isBlank = (val: any) => {
  if (typeof val === "boolean") return false;
  if (typeof val === "function") return false;
  if (typeof val === "number") return _.isNaN(val);

  return _.isEmpty(val);
};

export const isPresent = (val: any) => !isBlank(val);
