import React from "react";
import { withTenantPath } from "~/utils/url";
import { Modal } from "antd";
import BlockUI from "~/components/utils/BlockUI";
import { useWaitingContext, useErrorInfoContext } from "~/contexts/PageContext";

type Props = {
  children: React.ReactNode;
};

const Page: React.FC<Props> = ({ children }) => {
  const isWaiting = useWaitingContext();
  const errorInfo = useErrorInfoContext();

  const handleError = () => {
    const { statusCode } = errorInfo;
    if (statusCode !== 401 && statusCode !== 403 && statusCode !== 404) return;

    // 401 は(再)認証が必要なためログイン画面へ
    // 403 は認可がなく操作が続行できないためホーム画面へ
    // 404 は当該データがなく操作が続行できないためホーム画面へ
    if (statusCode === 401) {
      window.location.href = withTenantPath("/login");
    } else {
      window.location.href = withTenantPath("/home");
    }
  };

  if (errorInfo.message) {
    Modal.warning({ content: errorInfo.message, onOk: handleError });
  }

  return <BlockUI blocking={isWaiting}>{children}</BlockUI>;
};

Page.displayName = "Page";

export default Page;
