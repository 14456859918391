/* eslint-disable */
// @ts-nocheck
const registeredComponents = new Map();

export default {
  /**
   * @param components { component1: component1, component2: component2, etc. }
   */
  register(components) {
    Object.keys(components).forEach(name => {
      if (registeredComponents.has(name)) {
        console.warn(
          // eslint-disable-line no-console
          "register関数の引数に渡しているコンポーネント名が重複しています",
          name,
        );
      }

      const component = components[name];
      if (!component) {
        throw new Error(`${name} という名称のコンポーネントがnullです`);
      }

      registeredComponents.set(name, component);
    });
  },

  /**
   * @param name
   * @returns component
   */
  get(name) {
    if (registeredComponents.has(name)) {
      return registeredComponents.get(name);
    }

    const keys = Array.from(registeredComponents.keys()).join(", ");
    throw new Error(`${name} で登録されたコンポーネントが見つかりません。 \
登録されたコンポーネントには[ ${keys} ]が含まれています。登録忘れの可能性?`);
  },

  /**
   * Get a Map containing all registered components. Useful for debugging.
   * @returns Map where key is the component name and values are the
   * { name, component, renderFunction, isRenderer}
   */
  components() {
    return registeredComponents;
  },
};
