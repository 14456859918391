// コンポーネント外部でも値を更新・参照するため `recoil-external-state` 拡張を使う
import { atom, useRecoilValue, useSetRecoilState } from "~/utils/recoil-external-state";

// テナント名(`sampel` など)
const tenantNameStore = atom<string>({ key: "GlobalContext_tenantName", default: "" });
export const useTenantName = () => useRecoilValue<string>(tenantNameStore);
export const useSetTenantName = () => useSetRecoilState<string>(tenantNameStore);

// User#web_role が admin の場合 true
const isAdminStore = atom<boolean>({ key: "GlobalContext_isAdmin", default: false });
export const useIsAdmin = () => useRecoilValue<boolean>(isAdminStore);
export const useSetIsAdmin = () => useSetRecoilState<boolean>(isAdminStore);
