import { atom, useRecoilValue, useSetRecoilState } from "recoil";

// ローディング待機状態
const waitingStore = atom<boolean>({ key: "PageContext_waitingStore", default: false });
export const useWaitingContext = () => useRecoilValue(waitingStore);
export const useSetWaitingContext = () => useSetRecoilState(waitingStore);

type ErrorInfo = {
  /** 401, 403 等のステータスコード */
  statusCode: number | null;
  message: string | null;
};
const errorInfoStore = atom<ErrorInfo>({
  key: "PageContext_errorInfoStore",
  default: { statusCode: null, message: null },
});
export const useErrorInfoContext = () => useRecoilValue(errorInfoStore);
export const useSetErrorInfoContext = () => useSetRecoilState(errorInfoStore);
